import {
  Box,
  Image,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  Spacer,
  Button,
  Select,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { useAddQuestionMutation, useGetQuestionQuery, useUpdateQuestionMutation } from '../redux/apiSlice';
import { useFieldArray, useForm } from 'react-hook-form';
import { FileInputControl } from './FileInputControl';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Translation } from '../redux/types';
import { DevTool } from '@hookform/devtools';
import { LANGUAGES } from '../lib/languages';
import { toast } from 'react-toastify';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AddQuestionProps extends BoxProps {
  type: 'QuestionSlider' | 'QuestionWithOption';
}

interface AddQuestionInputProps {
  name: string;
  image: string;
  translations: Translation[];
}

export const AddQuestionPage = ({ type }: AddQuestionProps) => {
  const [addQuestion] = useAddQuestionMutation();
  const selectRef = useRef<HTMLSelectElement>(null);
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);

  const {
    register,
    reset,
    formState: { errors, dirtyFields },
    handleSubmit,
    control,
    watch,
  } = useForm<AddQuestionInputProps>({
    defaultValues: {
      translations: [
        {
          key: 'en',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'translations',
    control,
  });

  const onFileChange = (data: any) => {
    setImagePreview(data);
  };

  const availableLanguages = useMemo(() => {
    const usedLanguages = fields.map((x) => x.key);
    return Object.entries(LANGUAGES).filter(([key, value]) => !usedLanguages.includes(key));
  }, [fields]);

  const onSubmit = (data: AddQuestionInputProps) => {
    const { image, translations, ...rest } = data;

    const submitData = {
      ...rest,
      translations: Object.assign({}, ...translations.map((x: any) => ({ [x.key]: x }))),
      ...(image ? { image } : {}),
      resourcetype: type,
      enabled: true,
      // Page will be removed later
      page: type === 'QuestionWithOption' ? 1 : 2,
    };

    addQuestion(submitData)
      .unwrap()
      .then((response) => {
        if (response.id) {
          navigate(`/dashboard/questions/${response.id}`);
        }
        toast.success('Question updated');
      })
      .catch((error) => {
        toast.error('Error updating question');
      });
  };

  return (
    <Box>
      <Heading size="md">Add Question</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          p={6}
          mt={4}
          bg="bg.surface"
          boxShadow={{ base: 'none', md: 'sm' }}
          borderRadius={{ base: 'none', md: 'lg' }}>
          <VStack spacing={6} align="left">
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                {...register('name', {
                  required: 'Name is required.',
                })}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Text>{type === 'QuestionWithOption' ? 'Question' : 'Slider'}</Text>
            </FormControl>

            {type === 'QuestionWithOption' && (
              <FormControl isInvalid={!!errors?.image}>
                <FormLabel>Image</FormLabel>
                {imagePreview && <Image my={4} width="200px" src={imagePreview} />}
                <FileInputControl
                  onFileChange={onFileChange}
                  onFileChangeRemoveBase64={false}
                  rules={{ required: 'Image is required' }}
                  control={control}
                  name="image"
                  accept="image/*"
                  placeholder="Filename"
                />
                <FormErrorMessage>{errors.image?.message}</FormErrorMessage>
              </FormControl>
            )}

            {/* <Button alignSelf={'flex-end'} type="submit">
              Save
            </Button> */}
          </VStack>
        </Box>
        <Heading my={4} size="md">
          Translations
        </Heading>

        <Box p={4} as="section" bg="bg.surface" boxShadow={{ base: 'none', md: 'sm' }}>
          <Text>Add language</Text>
          <Stack direction="row" spacing={4} mt={2}>
            <Select ref={selectRef} placeholder="Select new language">
              {availableLanguages &&
                availableLanguages.map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
            </Select>

            <Button
              leftIcon={<AddIcon />}
              variant="solid"
              onClick={() => {
                if (selectRef.current) {
                  const value = selectRef.current.value;
                  if (value) {
                    append({
                      key: selectRef.current.value,
                    });
                  }
                }
              }}>
              Add
            </Button>
          </Stack>
        </Box>

        {fields.map((field, index) => (
          <Box
            key={field.id}
            p={6}
            mt={4}
            bg="bg.surface"
            boxShadow={{ base: 'none', md: 'sm' }}
            borderRadius={{ base: 'none', md: 'lg' }}>
            {/* QuestionWithOptions */}
            <Text mb={4}>
              {LANGUAGES[fields[index].key]} ({fields[index].key})
            </Text>
            <FormControl isRequired>
              <FormLabel>Text</FormLabel>
              <Input type="text" {...register(`translations.${index}.text`)} />
              <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>{' '}
            </FormControl>
            {type === 'QuestionWithOption' && (
              <>
                <Stack direction="row" spacing={4} mt={2}>
                  <FormControl isRequired>
                    <FormLabel>Yes</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.option1`, {
                        required: 'Option 1 is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>No</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.option2`, {
                        required: 'Option 2 is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Skip</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.option3`, {
                        required: 'Option 3 is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </>
            )}

            {/* Slider */}
            {type === 'QuestionSlider' && (
              <>
                <Stack direction="row" spacing={4} mt={2}>
                  <FormControl isRequired>
                    <FormLabel>Left text</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.text_left`, {
                        required: 'Left text is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Right Text</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.text_right`, {
                        required: 'Right text is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </>
            )}

            {/* Buttons */}
            <HStack mt={4}>
              <Button
                isDisabled={fields[index].key === 'en'}
                leftIcon={<DeleteIcon />}
                alignSelf={'flex-end'}
                type="submit"
                onClick={() => remove(index)}>
                Remove
              </Button>
              <Spacer />
            </HStack>
          </Box>
        ))}

        <Box
          p={6}
          mt={4}
          bg="bg.surface"
          boxShadow={{ base: 'none', md: 'sm' }}
          borderRadius={{ base: 'none', md: 'lg' }}>
          <HStack>
            <Button bgColor="red" leftIcon={<DeleteIcon />} alignSelf={'flex-end'} onClick={
              () => navigate('/dashboard/questions')
            }>
              Cancel
            </Button>
            <Spacer />
            <Button alignSelf={'flex-end'} type="submit">
              Save
            </Button>
          </HStack>
        </Box>

        <DevTool control={control} />
      </form>
    </Box>
  );
};
