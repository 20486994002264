import { Box, Divider, Heading, Text } from '@chakra-ui/react';

export default function QrCodePage() {
  return (
    <Box w="100%" p={4}>
      <Heading>404 Page Not Found</Heading>
      <Divider />
    </Box>
  );
}
