// Main Colors:
export const freshBlue = '#1D32B0';
export const mintyMint = '#8CD0D8';
const white = '#FFFFFF';
const black = '#000000';
const smilefix = '#F7E997';
// Gradients:
const fluorideAndWaterGradient = 'linear-gradient(88.38deg, #4276B8 -14.28%, #73C8DF 112.34%)';
const smilefixGradient = 'linear-gradient(88.38deg, #F2B883 -14.28%, #FFD583 112.34%)';
// Feedback Colors:
// 50 shades of gray:
const missingTeeth = '#737980';
const toughTooth = '#EFEFEF';
const scaryCavities = '#C8C8C8';
const candyCaries = '#383838';
const whiteTransparent = 'rgba(255, 255, 255, 0.2)';
const yellow = '#FFD583';
const positive = '#1FCF79';
const negative = '#FF637F';

export const colors = {
  loginPage: {
    background: mintyMint,
  },
  priceCard: {
    background: freshBlue,
    title: mintyMint,
    priceText: smilefix,
  },
  titleText: candyCaries,
  linkText: freshBlue,
  button: {
    background: freshBlue,
    hoverBackground: negative,
    disabledBackground: scaryCavities,
    text: white,
  },
  gradientBlue: fluorideAndWaterGradient,
  gradientYellow: smilefixGradient,
  white: white,
  black: black,
  progressIndicator: {
    backgroundActive: yellow,
    backgroundNonActive: whiteTransparent,
    textActive: candyCaries,
    textNonActive: white,
  },
  text: {
    black: candyCaries,
    grey: missingTeeth,
    blue: freshBlue,
    white: white,
    yellow: smilefix,
    green: mintyMint,
  },
  tabSwitcher: {
    background: {
      active: candyCaries,
      nonActive: toughTooth,
    },
    text: {
      active: white,
      nonActive: candyCaries,
      disabled: scaryCavities,
    },
  },
  status: {
    new: mintyMint,
    seen: freshBlue,
    urgent: negative,
    sent: candyCaries,
  },
  positiveColor: positive,
  negativeColor: negative,
  neutralColor: scaryCavities,
  table: {
    headerBorderBottom: scaryCavities,
    recordBorderBottom: toughTooth,
  },
  input: {
    textColor: candyCaries,
    border: scaryCavities,
  },
  emailNotification: {
    backgroundGreen: positive,
    backgroundRed: negative,
  },
};
