import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  Icon,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { FiFile } from 'react-icons/fi';


// extends UseControllerProps<T>
interface FileInputControlProps<T extends FieldValues> extends UseControllerProps<T> {
  placeholder?: string;
  accept?: string;
  maxSize?: number;
  onFileChange?: any;
  onFileChangeRemoveBase64?: boolean;
}


export const FileInputControl = <T extends FieldValues>(props
: FileInputControlProps<T>) => {
  const { placeholder, accept, maxSize,onFileChange, onFileChangeRemoveBase64,   ...controllerProps } = props;
  const { name, rules, shouldUnregister, control, disabled, ...rest } = controllerProps;

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules
  });
  const [filename, setFilename] = useState(placeholder);


  

  const displayName = useMemo( () => {

    if (filename) {
      try {
        let displayName = new URL(filename).pathname.split('/').pop();
        return displayName || "";
      } catch (e) {
        return "";
      }
    }
    return "";
  }, [filename] )

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    const fileName = event.target.files[0].name;
    setFilename(fileName);
    const size = event.target.files[0].size;
    const base64string = await fileToBaseB(event.target.files[0]);
    const image = await loadImage(base64string);

    // Validate
    if (maxSize && size > maxSize) {
    }

    if (onFileChange) {
      if (onFileChangeRemoveBase64) {
        onFileChange(base64string.split('base64,')[1])  
      } else {
        onFileChange(base64string)
      }
      
    }
    field.onChange(base64string.split('base64,')[1]);
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  return (
    <InputGroup onClick={() => handleClick()} {...rest}>
      <InputLeftElement pointerEvents="none">
        <Icon as={FiFile} />
      </InputLeftElement>
      <input type="file" accept={accept} ref={inputRef} style={{ display: 'none' }} onChange={onChange} />
      <Input placeholder={placeholder} readOnly={true} value={filename} />
      <InputRightElement width='4.5rem'>
        <Button>Select</Button>
      </InputRightElement>
    </InputGroup>
  );
};

const fileToBaseB = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read the file'));
      }
    };
    reader.onerror = () => reject(reader.error);
  });

const loadImage = (base64image: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = base64image;
  });
