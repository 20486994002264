import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { CustomerSupport } from '../components/CustomerSupport';
import { HelpFileList } from '../components/HelpFileList';
import { ShareLinkPanel } from '../components/ShareLinks';
import { TermsOfServicePanel } from '../components/TermsOfServicePanel';

export const HelpCenterPage = () => (
  <Container as="section">
    <Box as="section" bg="bg.surface" boxShadow="sm" borderRadius="lg" py={{ base: '16', md: '24' }}>
      <Stack spacing={{ base: '8', md: '10' }} align="center">
        <Stack spacing={{ base: '4', md: '6' }} textAlign="center">
          <Stack spacing="3">
            <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="medium" color="accent">
              Support
            </Text>
            <Heading size={{ base: 'md', md: 'lg' }} fontWeight="semibold">
              Welcome to Help Center
            </Heading>
          </Stack>
          <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }} maxW="3xl">
            How can we help you?
          </Text>
        </Stack>
      </Stack>
    </Box>

    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '0', md: '8' }}>
      <Flex direction="column" flex={1}>
        <CustomerSupport />
        <ShareLinkPanel />
      </Flex>

      <Flex direction="column" flex={1}>
        <HelpFileList />
        <TermsOfServicePanel />
      </Flex>
    </Stack>
  </Container>
);
{
  /* <Box>
<Heading as="h2" size="md">
  Contact Customer Support
</Heading>
<Text>
  If you need immediate assistance, please contact our support team at{' '}
  <Link href="tel:+1234567890">+1 (234) 567-890</Link> or email us at{' '}
  <Link href="mailto:support@example.com">support@example.com</Link>.
</Text>
</Box> */
}
