import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { SignInForm } from '../components/SignInForm';
import { SmilefixLogo } from '../assets/icon/SmilefixLogo';
import { useEffect } from 'react';
import { selectUserState, UserState } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface LoginPageProps {
  register?: boolean;
  forgotPassword?: boolean
}

export default function LoginPage({ register, forgotPassword }: LoginPageProps) {
  const userState = useSelector(selectUserState);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState === UserState.LoggedIn) {
      navigate('/dashboard');
    }
  }, [userState, navigate]);

  return (
    <Flex
      minH={{ base: 'auto', md: '100vh' }}
      bgGradient={{
        md: 'linear(to-r, bg.accent.default 50%, bg.surface 50%)',
      }}>
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          <Flex direction="column" px={{ base: '4', md: '8' }} height="full" color="fg.accent.default">
            <Flex align="center" mt={8}>
              <SmilefixLogo boxSize={32} />
            </Flex>
            <Flex flex="1" align="center">
              <Stack spacing="8">
                <Stack spacing="6">
                  <Heading size={{ md: 'lg', xl: 'xl' }}>Streamline your patient acquisition</Heading>
                  <Text textStyle="lg" maxW="md" fontWeight="medium" color="fg.accent.muted">
                    Create an account and help your customers find their true smile
                  </Text>
                </Stack>
                <HStack spacing="4">
                  <AvatarGroup size="md" max={useBreakpointValue({ base: 2, lg: 5 })} borderColor="fg.accent.default">
                    <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                    <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                    <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                    <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
                    <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                  </AvatarGroup>
                  <Text fontWeight="medium">Join 10.000+ users</Text>
                </HStack>
              </Stack>
            </Flex>
            <Flex align="center" h="24">
              <Text color="white" textStyle="sm">
                © 2023 Smilefix. All rights reserved.
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Center flex="1">
          <SignInForm forgotPassword={forgotPassword} register={register} px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} width="full" maxW="md" />
        </Center>
      </Flex>
    </Flex>
  );
}
