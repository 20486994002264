import { Button, Divider, Link, Heading, HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Link as ReachLink } from 'react-router-dom';
import { GoogleIcon } from '../assets/icon/ProviderIcons';
import { SmilefixLogo } from '../assets/icon/SmilefixLogo';
import { appSignInWithPopup } from '../features/auth/authSlice';
import { AppDispatch } from '../redux/store';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { LoginForm } from './LoginForm';
import { RegisterForm } from './RegisterForm';

interface SignInFormProps extends StackProps {
  register?: boolean;
  forgotPassword?: boolean;
}

export const SignInForm = ({ register, forgotPassword, ...props }: SignInFormProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleGoogleLogin = () => {
    dispatch(appSignInWithPopup());
  };

  return (
    <Stack spacing="8" {...props}>
      <Stack spacing="6" align="center">
        <SmilefixLogo color="#1D32B0" display={{ md: 'none' }} boxSize={32} />

        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
          {register && (
            <>
              <Heading size={{ base: 'xs', md: 'sm' }}>Create an account</Heading>
              <Text color="fg.muted">
                Already have an account?{' '}
                <Link as={ReachLink} to="/">
                  Log in
                </Link>
              </Text>
            </>
          )}
          {forgotPassword && (
            <>
              <Heading size={{ base: 'xs', md: 'sm' }}>Forgot password?</Heading>
              <Text color="fg.muted">
              Enter your email and we'll send you a link to reset your password.

              </Text>
            </>
          )}
          {!register && !forgotPassword && (
            <>
              <>
                <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
                <Text color="fg.muted">
                Don't have an account?{' '}
                  <Link as={ReachLink} to="/register">
                    Sign up
                  </Link>
                </Text>
              </>
            </>
          )}
        </Stack>
      </Stack>

      {register && <RegisterForm />}
      {forgotPassword && <ForgotPasswordForm />}
      {!register && !forgotPassword && <LoginForm />}

      {!forgotPassword && (
        <Stack spacing="4">
          <HStack>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
              {register ? 'or sign up with' : 'or continue with'}
            </Text>
            <Divider />
          </HStack>

          <Button onClick={() => handleGoogleLogin()} variant="secondary" leftIcon={<GoogleIcon />}>
            Sign in with Google
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default SignInForm;
