import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  CardProps,
  Spacer,
  IconButton,
} from '@chakra-ui/react';
import { Question } from '../redux/types';

interface QuestionSliderProps extends CardProps {
  isSelectMode?: boolean;
  question: Question;
  onSelect?: any;
  onDelete?: any;
}

export default function QuestionSlider({ onDelete, isSelectMode, question, onSelect, ...props }: QuestionSliderProps) {
  const { name, translations, ordering, page, resourcetype, enabled, image } = question;

  return (
    <Card maxW="sm" {...props} onClick={onSelect}>
      <CardHeader>
        <Flex>
          <Heading size="sd">{name}</Heading>
          <Spacer />
          {isSelectMode === true ? (
            <></>
          ) : (
          <IconButton icon={<DeleteIcon />} onClick={() => onDelete(question)} aria-label={''} />
          )}
        </Flex>
      </CardHeader>

      <CardBody paddingTop="0">
        <Flex>
          <Text fontSize="sm">{translations.en.text_left}</Text>
          <Spacer />
          <Text fontSize="sm">{translations.en.text_right}</Text>
        </Flex>
        <Slider defaultValue={0} min={-3} max={3} step={1} isReadOnly={isSelectMode}>
          <SliderTrack>
            <Box position="relative" right={10} />
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={4} />
        </Slider>
      </CardBody>
    </Card>
  );
}
