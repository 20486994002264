import { theme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';

const proTheme = extendTheme(theme);

const overrides = {
  colors: {
    freshblue: '#1D32B0',
    brand: {
      100: '#1D32B0',
      200: '#1D32B0',
      300: '#1D32B0',
      400: '#1D32B0',
      500: '#1D32B0',
      600: '#1D32B0',
      700: '#1D32B0',
      800: '#1D32B0',
      900: '#1D32B0',
    },
    positive: '#1FCF79',
    negative: '#FF637F',
    neutral: '#C8C8C8',
  },
  components: {
    Divider: {
      baseStyle: {
        my: 4,
        borderColor: 'gray.300',
      },
    },

    Button: {
      baseStyle: {
        //borderRadius: 'none','
      },
      sizes: {
        small: {
          px: 5,
          h: '50px',
          fontSize: '20px',
        },
        medium: {
          px: 7,
          h: '60px',
          fontSize: '25px',
        },
        large: {
          px: 8,
          h: '70px',
          fontSize: '30px',
          borderRadius: '10px',
        },
      },
      variants: {
        outline: {
          _active: {
            'borderColor': '#1D32B0',
            color: 'white',
          },
        },

        primary: {
          bg: 'freshblue',
          color: 'white',
        },
        secondary: {
          bg: 'secondary',
          //color: '#fff',
        },
        ghost: {
          bg: 'transparent',
          border: '1px solid red',
        },
        primaryGhost: {
          bg: 'transparent',
          border: '1px solid',
          borderColor: 'primary',
        },
        secondaryGhost: {
          bg: 'transparent',
          border: '1px solid',
          borderColor: 'secondary',
          _hover: {
            color: '#fff',
            bg: '#BB1415',
          },
        },
      },
    },
    Tag: {
      baseStyle: {
        container: {
          borderRadius: 'full',
          cursor: 'pointer'
        },
      },
      variants: {},
      defaultProps: {
        colorScheme: 'brand',    
        
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'normal',
        fontFamily: `hoss-round, sans-serif`,
      },
      sizes: {
        small: {
          //fontSize: '20px',
        },
        medium: {
          //fontSize: '25px'
        },
        large: {
          //fontSize: '30px'
        },
      },
    },
  },
};

export const myTheme = extendTheme(overrides, proTheme);
