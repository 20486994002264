import React, { ReactNode,  } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';

import { useAuth } from '../../hooks/useAuth';
import { usePermissions } from '../../hooks/usePermissions';
import NotFoundPage from '../../routes/NotFoundPage';

interface IAuthRouteProps {
  children: ReactNode;
  requireAdmin: boolean;
}

const AuthRoute = ({children, requireAdmin}: IAuthRouteProps) => {
  const { userId, state } = useAuth();
  const location = useLocation()
  const {isAdmin} = usePermissions()

  console.log("j")
  console.log(isAdmin)
  console.log(requireAdmin)

  if (requireAdmin && !isAdmin) {
    return <NotFoundPage/>
  }
  
  if (!userId) {
    return <Navigate to="/" state={{ from: location }}/>;
  }
  return <>{children}</>;
};

export default AuthRoute;
