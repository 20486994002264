import { Flex, Icon } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

export const NavItem = (props: any) => {
  const { icon, children, to, ...rest } = props;
  return (
    <NavLink to={to}>
      <Flex
        align="center"
        rounded="md"
        py="3"
        cursor="pointer"
        color="white"
        _hover={{
          bg: 'blackAlpha.300',
          color: 'whiteAlpha.900',
        }}
        role="group"
        fontWeight="semibold"
        fontSize="md"
        transition=".15s ease"
        {...rest}>
        {icon && (
          <Icon
            ml={3}
            mr="3"
            boxSize="4"
            _groupHover={{
              color: 'gray.300',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  );
};
