import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from '../config/config';
import { RootState } from './store';
import {
  Customer,
  Guide,
  Language,
  Proposal,
  ProposalPostData,
  Question,
  Questionnaire,
  QuestionPostData,
  Template,
  User,
} from './types';

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['User', 'Questions', 'Questionnaires', 'Guides', 'Languages', 'Customers', 'Templates', 'Proposals'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiBaseUrl}/api`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).auth.token;

      headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => `/users/me`,
      providesTags: (result, error, id) => [{ type: 'User', undefined }],
    }),
    sendProposalEmail: builder.mutation<User, string>({
      query: (id, ...patch) => ({
        url: `/proposals/${id}/send_email/`,
        method: 'POST',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Proposals', id: arg }],
    }),
    stripeCheckout: builder.mutation<User, string>({
      query: (id, ...patch) => ({
        url: `/users/${id}/create_checkout_session/`,
        method: 'POST',
        body: patch,
      }),
      //invalidatesTags: (result, error, arg) => [{ type: 'Proposals', id: arg }],
    }),
    updateUser: builder.mutation<User, Partial<User>>({
      query: ({ id, ...patch }) => ({
        url: `/users/me/`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          //api.util.updateQueryData('getUser', id, (draft) => {
          api.util.updateQueryData('getUser', undefined, (draft) => {
            Object.assign(draft, patch);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          console.log("error")
          patchResult.undo();
        }
      },
    }),

    getCustomers: builder.query<Customer[], void>({
      query: () => '/customers/',
      providesTags: (result = [], error, arg) => [
        'Customers',
        ...result.map(({ id }) => ({ type: 'Customers' as const, id })),
      ],
    }),
    updateCustomer: builder.mutation<Customer, Partial<Customer> & Pick<Customer, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/customers/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { data: updatedCustomer } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getCustomers', undefined, (draftCustomers) => {
            const index = draftCustomers.findIndex((customer) => customer.id === updatedCustomer.id);
            if (index !== -1) draftCustomers[index] = updatedCustomer;
          }),
        );
      },
    }),
    deleteCustomer: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/customers/${id}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data: deletedCustomerResponse } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getCustomers', undefined, (draftCustomers) => {
            const index = draftCustomers.findIndex((customer) => customer.id === id);
            if (index !== -1) draftCustomers.splice(index, 1);
          }),
        );
      },
    }),
    addProposal: builder.mutation<Proposal, Omit<ProposalPostData, 'id'>>({
      query: (body) => ({
        url: '/proposals/',
        method: 'POST',
        body,
      }),
      async onQueryStarted({ ...body }, { dispatch, queryFulfilled }) {
        const { data: newProposal } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getProposals', undefined, (draftProposals) => {
            draftProposals.push(newProposal);
          }),
        );
      },
    }),
    getProposals: builder.query<Proposal[], void>({
      query: () => '/proposals/',
      providesTags: (result = [], error, arg) => [
        'Proposals',
        ...result.map(({ id }) => ({ type: 'Proposals' as const, id })),
      ],
    }),
    updateProposal: builder.mutation<Proposal, Partial<Proposal> & Pick<Proposal, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/proposals/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { data: updatedProposal } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getProposals', undefined, (draftProposals) => {
            const index = draftProposals.findIndex((proposal) => proposal.id === updatedProposal.id);
            if (index !== -1) draftProposals[index] = updatedProposal;
          }),
        );
      },
    }),
    deleteProposal: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/proposals/${id}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data: deletedProposalResponse } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getProposals', undefined, (draftProposals) => {
            const index = draftProposals.findIndex((proposal) => proposal.id === id);
            if (index !== -1) draftProposals.splice(index, 1);
          }),
        );
      },
    }),
    getTemplates: builder.query<Template[], void>({
      query: () => '/templates/',
      providesTags: (result = [], error, arg) => [
        'Templates',
        ...result.map(({ id }) => ({ type: 'Templates' as const, id })),
      ],
    }),
    addTemplate: builder.mutation<Template, Omit<Template, 'id'>>({
      query: (body) => ({
        url: '/templates/',
        method: 'POST',
        body,
      }),
      async onQueryStarted({ ...body }, { dispatch, queryFulfilled }) {
        const { data: newTemplate } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getTemplates', undefined, (draftTemplates) => {
            draftTemplates.push(newTemplate);
          }),
        );
      },
    }),
    updateTemplate: builder.mutation<Template, Partial<Template> & Pick<Template, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/templates/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { data: updatedTemplate } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getTemplates', undefined, (draftTemplates) => {
            const index = draftTemplates.findIndex((customer) => customer.id === updatedTemplate.id);
            if (index !== -1) draftTemplates[index] = updatedTemplate;
          }),
        );
      },
    }),
    deleteTemplate: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/templates/${id}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data: any } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getTemplates', undefined, (draftTemplates) => {
            const index = draftTemplates.findIndex((template) => template.id === id);
            if (index !== -1) draftTemplates.splice(index, 1);
          }),
        );
      },
    }),
    getQuestion: builder.query<Question, string>({
      query: (id) => `/questions/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Questions', id }],
    }),

    getQuestions: builder.query<Question[], void>({
      query: () => '/questions/',
      providesTags: (result = [], error, arg) => [
        'Questions',
        ...result.map(({ id }) => ({ type: 'Questions' as const, id })),
      ],
    }),
    addQuestion: builder.mutation<Question, Omit<QuestionPostData, 'id'>>({
      query: (body) => ({
        url: '/questions/',
        method: 'POST',
        body,
      }),
      async onQueryStarted({ ...body }, { dispatch, queryFulfilled }) {
        const { data: newQuestion } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getQuestions', undefined, (draftQuestions) => {
            draftQuestions.push(newQuestion);
          }),
        );
      },
    }),
    updateQuestion: builder.mutation<Question, Partial<Question> & Pick<Question, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/questions/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { data: updatedQuestion } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getQuestions', undefined, (draftQuestions) => {
            const index = draftQuestions.findIndex((question) => question.id === updatedQuestion.id);
            if (index !== -1) draftQuestions[index] = updatedQuestion;
          }),
        );
      },
    }),
    deleteQuestion: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/questions/${id}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data: any } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getQuestions', undefined, (draftQuestions) => {
            const index = draftQuestions.findIndex((question) => question.id === id);
            if (index !== -1) draftQuestions.splice(index, 1);
          }),
        );
      },
    }),
    getQuestionnaires: builder.query<Questionnaire[], void>({
      query: () => '/questionnaires/',
      providesTags: (result = [], error, arg) => [
        'Questionnaires',
        ...result.map(({ id }) => ({ type: 'Questionnaires' as const, id })),
      ],
    }),
    //Partial<Questionnaire> & Pick<Questionnaire, 'id'>
    updateQuestionnaire: builder.mutation<Questionnaire, any>({
      query: ({ id, ...patch }) => ({
        url: `/questionnaires/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { data: updatedQuestionnaire } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getQuestionnaires', undefined, (draftQuestionnaires) => {
            const index = draftQuestionnaires.findIndex(
              (questionnaire) => questionnaire.id === updatedQuestionnaire.id,
            );
            if (index !== -1) draftQuestionnaires[index] = updatedQuestionnaire;
          }),
        );
      },
    }),
    getGuides: builder.query<Guide[], void>({
      query: () => '/guides/',
      providesTags: ['Guides'],
    }),
    getLanguages: builder.query<Language[], void>({
      query: () => '/languages/',
      providesTags: ['Languages'],
    }),
  }),
});

export const {
  useAddQuestionMutation,
  useGetQuestionQuery,
  useUpdateQuestionMutation,
  useStripeCheckoutMutation,
  useSendProposalEmailMutation,
  useUpdateProposalMutation,
  useAddProposalMutation,
  useGetTemplatesQuery,
  useDeleteCustomerMutation,
  useUpdateTemplateMutation,
  useAddTemplateMutation,
  useGetUserQuery,
  useDeleteTemplateMutation,
  useUpdateUserMutation,
  useUpdateCustomerMutation,
  useGetCustomersQuery,
  useGetQuestionsQuery,
  useGetQuestionnairesQuery,
  useUpdateQuestionnaireMutation,
  useGetGuidesQuery,
  useGetLanguagesQuery,
  useDeleteProposalMutation,
  useGetProposalsQuery,
} = api;

/*
export const setCustomerToActive: any =

    return fetch(`${API_BASE_URL}/api/customers/${customer.id}/set_seen/`, {

            toast.success('Notification email sent to client');
          } else {
            toast.error('Error sending notification email to client');

  };
*/

// export const sendEmail: any = (proposal: Proposal) => async (dispatch: AppDispatch, getState: () => RootState) => {
//   const state = getState();

//   return fetch(`${API_BASE_URL}/api/proposals/${proposal.id}/send_email/`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Token ${state.auth.token}`,
//     },
//     body: JSON.stringify({}),
//   })

//dispatch(setProposalSentDate(proposal));
/*
    setProposalSentDate: (state, action: PayloadAction<Proposal>) => {
      let index = -1;
      let customerIndex = -1;
      if (state.proposals) {
        index = state.proposals.findIndex((p: Proposal) => p.id === action.payload.id);
        if (index >= 0) {
          state.proposals[index].date_sent = new Date().toISOString();
        }
      }
      /*if (state.customers) {
        customerIndex = state.customers.findIndex((cust: Customer) => cust.id === action.payload.customer);
        if (customerIndex >= 0) {
          state.customers[customerIndex].proposal_date_sent = new Date().toISOString();
        }
      }*/
