import { ChakraProvider } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './components/Loader';
import { selectUserState } from './features/auth/authSlice';
import { myTheme } from './styles/chakra';
import LoginPage from './routes/LoginPage';
import AuthRoute from './features/auth/AuthRoute';
import DashboardLayout from './components/DashboardLayout';
import { HelpCenterPage } from './routes/HelpCenterPage';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import { EditQuestionPage } from './components/EditQuestionPage';
import { AddQuestionPage } from './components/AddQuestionPage';
import { EditQuestionnairePage } from './routes/EditQuestionnairePage';
import ListQuestionnairesPage from './routes/ListQuestionnairesPage';

const NotFoundPage = lazy(() => import('./routes/NotFoundPage'));
const ProposalsPage = lazy(() => import('./routes/ProposalsPage'));
const QuestionsPage = lazy(() => import('./routes/QuestionsPage'));
const SettingsPage = lazy(() => import('./routes/SettingsPage'));
const QrCodePage = lazy(() => import('./routes/QrCodePage'));
const StatisticsPage = lazy(() => import('./routes/StatisticsPage'));

const { ToastContainer, toast } = createStandaloneToast();

function App() {
  const userState = useSelector(selectUserState);

  return (
    <>
      <ToastContainer />
      <ToastifyContainer />
      <ChakraProvider theme={myTheme}>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/register" element={<LoginPage register={true} />} />
              <Route path="/forgot-password" element={<LoginPage forgotPassword={true} />} />
              {/* <Route path="/sidebar" element={<DashboardLayout/>}/> */}

              <Route
                path="/dashboard"
                element={
                  <AuthRoute requireAdmin={false}>
                    <DashboardLayout />
                  </AuthRoute>
                }>
                <Route index element={<ProposalsPage />} />
                <Route path="customers/:customerId" element={<ProposalsPage />} />
                <Route path="statistics" element={<StatisticsPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route path="questionnaire" element={<ListQuestionnairesPage />} />
                <Route path="questionnaire/:questionnaireId" element={<EditQuestionnairePage />} />
                <Route path="qrcode" element={<QrCodePage />} />
                <Route path="help" element={<HelpCenterPage />} />

                <Route
                  path="/dashboard/questions"
                  element={
                    <AuthRoute requireAdmin={true}>
                      <Outlet />
                    </AuthRoute>
                  }>
                  <Route index element={<QuestionsPage />} />
                  <Route path=":questionId" element={<EditQuestionPage />} />
                  <Route path="add/slider" element={<AddQuestionPage type="QuestionSlider" />} />
                  <Route path="add/question" element={<AddQuestionPage type="QuestionWithOption" />} />
                </Route>
              </Route>

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
