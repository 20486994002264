import { store } from '../../redux/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { auth, Providers } from '../../config/firebase';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { RootState } from '../../redux/store';

export enum UserState {
  Loading,
  LoggedIn,
  LoggedOut,
}

type AuthState = {
  userState: UserState;
  userId: any | null;
  token: string | null;
};

export interface LoginProps {
  email: string;
  password: string;
}

const initialState: AuthState = { userState: UserState.Loading, userId: null, token: null };

export const appSignInWithPopup = createAsyncThunk('appSignInWithPopup', async (_, { rejectWithValue }) => {
  try {
    const user = await signInWithPopup(auth, Providers.google);
    return user.user;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const appSignInWithEmailAndPassword = createAsyncThunk<
  any,
  LoginProps,
  {
    rejectValue: any;
  }
>('appSignInWithEmailAndPassword', async ({ email, password }, { rejectWithValue }) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    return user.user;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const appCreateUserWithEmailAndPassword = createAsyncThunk<
  any,
  LoginProps,
  {
    rejectValue: any;
  }
>('appCreateUserWithEmailAndPassword', async ({ email, password }, { rejectWithValue }) => {
  try {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    return user.user;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const appSendPasswordResetEmail = createAsyncThunk<
  void,
  string,
  {
    rejectValue: any;
  }
>('appSendPasswordResetEmail', async (email, { rejectWithValue }) => {
  try {
    console.log(email);
    return await sendPasswordResetEmail(auth, email);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const appLogout = createAsyncThunk<
  void,
  void,
  {
    rejectValue: any;
  }
>('appLogout', async (_, { rejectWithValue }) => {
  try {
    await signOut(auth);
  } catch (error) {
    return rejectWithValue(error);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setCredentials: (state, { payload: { user, token } }: PayloadAction<{ user: string; token: string }>) => {
      console.log('setCredentials', user);
      state.userId = user;
      state.token = token;
      state.userState = UserState.LoggedIn;
    },
    logout: (state) => {
      state.userId = null;
      state.token = null;
      state.userState = UserState.LoggedOut;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appLogout.pending, (state, action) => {
      state.userState = UserState.LoggedOut;
    });
    builder.addCase(appLogout.fulfilled, (state, action) => {
      state.userId = null;
      state.token = null;
    });
  },
});

auth.onAuthStateChanged((user) => {
  console.log('onAuthStateChanged', user);

  if (user) {
    user.getIdToken(true).then((idToken) => {
      store.dispatch(setCredentials({ user: user.uid, token: idToken }));
    });
    if (!user) {
      store.dispatch(logout());
    }
  }
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUserId = (state: RootState) => state.auth.userId;
export const selectUserState = (state: RootState) => state.auth.userState;
export const selectCurrentToken = (state: RootState) => state.auth.token;
