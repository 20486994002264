import {
  Box,
  Image,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  Spacer,
  Button,
  Select,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { useGetQuestionQuery, useUpdateQuestionMutation } from '../redux/apiSlice';
import Loader from './Loader';
import { useFieldArray, useForm } from 'react-hook-form';
import { FileInputControl } from './FileInputControl';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Translation } from '../redux/types';
import { DevTool } from '@hookform/devtools';
import { LANGUAGES } from '../lib/languages';
import { toast } from 'react-toastify';

interface EditQuestionProps extends BoxProps {}

interface QuestionInputProps {
  name: string;
  image: string;
  translations: Translation[];
}

export const EditQuestionPage = (props: EditQuestionProps) => {
  const { questionId } = useParams();
  const [updateQuestion] = useUpdateQuestionMutation();
  const { data: question, isLoading } = useGetQuestionQuery(questionId!);
  const selectRef = useRef<HTMLSelectElement>(null);
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);

  const {
    register,
    reset,
    formState: { errors, dirtyFields },
    handleSubmit,
    control,
  } = useForm<QuestionInputProps>({
    // NO schema, because form is dynamic. Specify fields on form.
    //resolver: yupResolver(questionFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'translations',
    control,
  });

  const onFileChange = (data: any) => {
    setImagePreview(data);
  };

  // default values for form
  useEffect(() => {
    if (question?.translations) {
      const translationsArray = Object.entries(question.translations).map(([key, value]) => {
        return { ...value, key: key };
      });
      reset({ name: question?.name, translations: translationsArray });
    }
  }, [question, reset]);

  const availableLanguages = useMemo(() => {
    const usedLanguages = fields.map((x) => x.key);
    return Object.entries(LANGUAGES).filter(([key, value]) => !usedLanguages.includes(key));
  }, [fields]);

  if (!question) {
    return <Loader />;
  }

  const onSubmit = (data: QuestionInputProps) => {
    const { image, translations, ...rest } = data;

    const submitData = {
      id: questionId!,
      ...rest,
      translations: Object.assign({}, ...translations.map((x: any) => ({ [x.key]: x }))),
      ...(image ? { image } : {}),
    };

    updateQuestion(submitData)
      .unwrap()
      .then(() => {
        toast.success('Question updated');
      })
      .catch((error) => {
        toast.error('Error updating question');
      });
  };

  return (
    <Box>
      <Heading size="md">Edit Question</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          p={6}
          mt={4}
          bg="bg.surface"
          boxShadow={{ base: 'none', md: 'sm' }}
          borderRadius={{ base: 'none', md: 'lg' }}>
          <VStack spacing={6} align="left">
            <FormControl isInvalid={!!errors?.name}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                {...register('name', {
                  required: 'Name is required.',
                })}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>Type</FormLabel>
              <Text>{question.resourcetype === 'QuestionWithOption' ? 'Question' : 'Slider'}</Text>
            </FormControl>

            {question.resourcetype === 'QuestionWithOption' && (
            <FormControl isInvalid={!!errors?.image}>
              <FormLabel>Image</FormLabel>
              <Image my={4} width="200px" src={ imagePreview ? imagePreview : question?.image} />
              <FileInputControl onFileChange={onFileChange} onFileChangeRemoveBase64={false} control={control} name="image" accept="image/*" placeholder="Filename" />
              <FormErrorMessage>{errors.image?.message}</FormErrorMessage>
            </FormControl>
            )}

          </VStack>
        </Box>
        <Heading my={4} size="md">
          Translations
        </Heading>

        <Box p={4} as="section" bg="bg.surface" boxShadow={{ base: 'none', md: 'sm' }}>
          <Text>Add language</Text>
          <Stack direction="row" spacing={4} mt={2}>
            <Select ref={selectRef} placeholder="Select new language">
              {LANGUAGES &&
                availableLanguages.map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
            </Select>

            <Button
              leftIcon={<AddIcon />}
              variant="solid"
              onClick={() => {
                if (selectRef.current) {
                  const value = selectRef.current.value;
                  if (value) {
                    append({
                      key: selectRef.current.value,
                    });
                  }
                }
              }}>
              Add
            </Button>
          </Stack>
        </Box>

        {fields.map((field, index) => (
          <Box
            key={field.id}
            p={6}
            mt={4}
            bg="bg.surface"
            boxShadow={{ base: 'none', md: 'sm' }}
            borderRadius={{ base: 'none', md: 'lg' }}>
            {/* QuestionWithOptions */}
            <Text mb={4}>
              {LANGUAGES[fields[index].key]} ({fields[index].key})
            </Text>
            <FormControl isRequired>
              <FormLabel>Text</FormLabel>
              <Input type="text" {...register(`translations.${index}.text`)} />
              <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>{' '}
            </FormControl>
            {question.resourcetype === 'QuestionWithOption' && (
              <>
                <Stack direction="row" spacing={4} mt={2}>
                  <FormControl isRequired>
                    <FormLabel>Yes</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.option1`, {
                        required: 'Option 1 is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>No</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.option2`, {
                        required: 'Option 2 is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Skip</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.option3`, {
                        required: 'Option 3 is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </>
            )}

            {/* Slider */}
            {question.resourcetype === 'QuestionSlider' && (
              <>
                <Stack direction="row" spacing={4} mt={2}>
                  <FormControl isRequired>
                    <FormLabel>Left text</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.text_left`, {
                        required: 'Left text is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Right Text</FormLabel>
                    <Input
                      type="text"
                      {...register(`translations.${index}.text_right`, {
                        required: 'Right text is required.',
                      })}
                    />
                    <FormErrorMessage>{errors.translations?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </>
            )}

            {/* Buttons */}
            <HStack mt={4}>
              <Button leftIcon={<DeleteIcon />} alignSelf={'flex-end'} type="submit" onClick={() => remove(index)}>
                Remove
              </Button>
              <Spacer />

            </HStack>
          </Box>
        ))}

<Box
          p={6}
          mt={4}
          bg="bg.surface"
          boxShadow={{ base: 'none', md: 'sm' }}
          borderRadius={{ base: 'none', md: 'lg' }}>
          <HStack>
            <Button bgColor="red" leftIcon={<DeleteIcon />} alignSelf={'flex-end'} onClick={
              () => navigate('/dashboard/questions')
            }>
              Cancel
            </Button>
            <Spacer />
            <Button alignSelf={'flex-end'} type="submit">
              Save
            </Button>
          </HStack>
        </Box>

        <DevTool control={control} />
      </form>
    </Box>
  );
};
