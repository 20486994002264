import {
  Text,
  Heading,
  Button,
  Flex,
  ModalProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import QuestionSlider from '../components/QuestionSlider';
import QuestionWithOption from '../components/QuestionWithOption';
import { useGetQuestionnairesQuery, useGetQuestionsQuery, useUpdateQuestionnaireMutation } from '../redux/apiSlice';
import { Question, Questionnaire } from '../redux/types';

export const EditQuestionnairePage = () => {
  const { questionnaireId } = useParams();
  const { data: questionnaires, isLoading } = useGetQuestionnairesQuery();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>({} as Questionnaire);
  const [questionFilter, setQuestionFilter] = useState('');
  const [updateQuestionnaire] = useUpdateQuestionnaireMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // set initial state of questionnaire
  useEffect(() => {
    if (questionnaires) {
      const questionnaire = questionnaires.find((q) => q.id === questionnaireId);
      if (questionnaire) {
        setQuestionnaire(questionnaire);
      }
    }
  }, [questionnaires, questionnaireId]);

  // filter questions per page
  const pageOneQuestions = useMemo(() => questionnaire?.questions?.filter((q) => q.page === 1), [questionnaire]);
  const pageTwoQuestions = useMemo(() => questionnaire?.questions?.filter((q) => q.page === 2), [questionnaire]);

  // create list of question ids to hide in modal
  const hideIds = useMemo(() => {
    if (questionnaire?.questions) {
      return questionnaire.questions.map((q) => q.id);
    }
    return [];
  }, [questionnaire]);

  // actions
  const handleAddQuestion = async (question: Question) => {
    onClose();
    // safeguard against adding the same question twice
    if (questionnaire?.questions.find((q) => q.id === question.id)) {
      return;
    }
    const newQuestions = questionnaire?.questions?.concat(question);
    //setQuestionnaire({ ...questionnaire, questions: newQuestions });
    await handleSaveQuestionnaire(newQuestions);
  };

  const handleDeleteQuestion = async (question: Question) => {
    const newQuestions = questionnaire?.questions?.filter((q) => q.id !== question.id);
    //setQuestionnaire({ ...questionnaire, questions: newQuestions });
    await handleSaveQuestionnaire(newQuestions);
  };

  const handleSaveQuestionnaire = async (questions: any) => {
    const patchData = {
      id: questionnaire.id,
      questions: questions.map((q: any) => {
        return { id: q.id, resourcetype: q.resourcetype };
      }),
    };

    await updateQuestionnaire(patchData)
      .unwrap()
      .then((res) => {
        toast.success('Questionnaire saved');
      })
      .catch((err) => {
        toast.error('Error saving questionnaire');
      });
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Flex flexDirection="column" gap={6} maxW="50%">
        <Heading size="md">Questionnaire</Heading>
        <Text>Questionnaire editor intro/help text to explain question categories.. </Text>
        <Heading size="sm">Pre-questions</Heading>

        <VStack spacing={4} p={4} borderWidth="4px" borderStyle="dashed" rounded="md" minH="50px" alignItems="center">
          {pageOneQuestions &&
            pageOneQuestions.map((question) => (
              <QuestionWithOption key={question.id} question={question} onDelete={handleDeleteQuestion} />
            ))}
        </VStack>
        <Button
          alignSelf={'flex-end'}
          onClick={() => {
            setQuestionFilter('QuestionWithOption');
            onOpen();
          }}>
          Add questions
        </Button>

        <Heading size="sm">Post-questions</Heading>
        <VStack spacing={4} p={4} borderWidth="4px" borderStyle="dashed" rounded="md" minH="50px" alignItems="center">
          {pageTwoQuestions &&
            pageTwoQuestions.map((question) => (
              <QuestionSlider width="100%" key={question.id} question={question} onDelete={handleDeleteQuestion} />
            ))}
        </VStack>

        <Button
          alignSelf={'flex-end'}
          onClick={() => {
            setQuestionFilter('QuestionSlider');
            onOpen();
          }}>
          Add slider
        </Button>
      </Flex>
      <SelectQuestionModal
        filter={questionFilter}
        onSelect={handleAddQuestion}
        isOpen={isOpen}
        onClose={onClose}
        children=""
        hideIds={hideIds}
      />
    </>
  );
};

interface SelectQuestionModalProps extends ModalProps {
  onSelect?: any;
  filter?: string;
  hideIds?: string[];
}

const SelectQuestionModal = ({ hideIds, filter, onSelect, ...props }: SelectQuestionModalProps) => {
  const { data: questions, isLoading } = useGetQuestionsQuery();
  const questionFiltered = useMemo(() => {
    if (questions) {
      const filteredQuestions = questions.filter((q) => !hideIds?.includes(q.id) && q.enabled === true);
      if (filter) {
        return filteredQuestions.filter((q) => q.resourcetype === filter);
      }
      return filteredQuestions;
    }
    return [];
  }, [questions, filter, hideIds]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select question</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>Click question to add it to the questionnaire.</Text>
          <Flex flexDirection="column" gap={4} width="100%">
            {questionFiltered &&
              questionFiltered.map((question) => {
                if (question.resourcetype === 'QuestionWithOption') {
                  return (
                    <QuestionWithOption
                      _hover={{
                        borderColor: '#1D32B0',
                        borderWidth: '2px',
                      }}
                      key={question.id}
                      question={question}
                      isSelectMode={true}
                      onSelect={() => onSelect(question)}
                    />
                  );
                } else if (question.resourcetype === 'QuestionSlider') {
                  return (
                    <QuestionSlider
                      _hover={{
                        borderColor: '#1D32B0',
                        borderWidth: '2px',
                      }}
                      key={question.id}
                      question={question}
                      isSelectMode={true}
                      onSelect={() => onSelect(question)}
                    />
                  );
                }
              })}
          </Flex>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
