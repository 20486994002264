import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Text,
  Box,
  Heading,
  Link,
  BoxProps,
  Stack,
  Button,
  InputGroup,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FacebookIcon, TwitterIcon, WhatsAppIcon } from '../assets/icon/SocialIcons';
import { useGetUserQuery } from '../redux/apiSlice';
import QrCode from '../routes/QrCodePage';

interface ShareLinkPanelProps extends BoxProps {}

export const ShareLinkPanel = (props: ShareLinkPanelProps) => {
  const { data: user } = useGetUserQuery();

  const copyToClipboard = async (content: string | undefined) => {
    if (content) {
      await navigator.clipboard.writeText(content);
      toast.success('Personal link copied to clipboard.');
    }
  };

  return (
    <Box {...props} as="section">
      <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
        <Stack spacing="5">
          <Stack spacing="4">
            <Text textStyle="lg" fontWeight="medium">
              Questionnaire links
            </Text>
            <Text textStyle="sm" color="fg.muted">
              You can share link to your Smilefix survey in following ways:
            </Text>
            <Text textStyle="sm" fontWeight="medium">
              1. Share QR-code
            </Text>
            <QrCode ml={2} />
            <Text textStyle="sm" fontWeight="medium">
              2. Share link
            </Text>
            <InputGroup>
              <Input value={user ? user.client_app_url : ''} isReadOnly />
              <InputRightElement>
                <Button onClick={() => copyToClipboard(user?.client_app_url)}>Copy</Button>
              </InputRightElement>
            </InputGroup>
            <Text textStyle="sm" fontWeight="medium">
              3. Embed on website (coming soon 2024)
            </Text>
          </Stack>

          <Stack spacing="3" direction={{ base: 'column', sm: 'column' }}></Stack>
        </Stack>
      </Box>
    </Box>
  );
};
