import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, HStack, Input, Stack } from '@chakra-ui/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { appSignInWithEmailAndPassword, LoginProps } from '../features/auth/authSlice';

let loginFormSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required().min(8),
  rememberMe: yup.boolean(),
});

type LoginFormInputs = yup.InferType<typeof loginFormSchema>;

interface LoginFormProps {
  register?: boolean;
}

export const LoginForm = ({ register: isRegisterPage, ...props }: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginFormSchema),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<LoginFormInputs> = async ({ email, password }) => {
    const login: LoginProps = { email, password };
    try {
      const userCredential = await dispatch(appSignInWithEmailAndPassword(login)).unwrap();
    } catch (err) {
      control.setError('password', { message: 'Invalid password' });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="5">
        <FormControl isInvalid={!!errors?.email}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input id="email" placeholder="Enter your email" {...register('email', { required: true })} />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.password}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input id="password" placeholder="********" type="password" {...register('password', { required: true })} />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <HStack justify="space-between">
          <Checkbox defaultChecked {...register('rememberMe')}>
            Remember me
          </Checkbox>
          <Button variant="text" size="sm" onClick={() => navigate('/forgot-password')}>
            Forgot password
          </Button>
        </HStack>
        <Button type="submit">Sign in</Button>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};
