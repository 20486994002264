import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { SmilefixLogoSmall } from '../assets/icon/SmilefixLogoSmall';
import { Outlet } from 'react-router-dom';
import { SidebarContent } from './SidebarContent';
import NotificationBanners from '../routes/NotificationBanners';

export default function DashboardLayout(props: any) {
  const sidebar = useDisclosure();

  return (
    <Box
      as="section"
      bg="gray.50"
      _dark={{
        bg: 'gray.700',
      }}
      minH="100vh">
      <SidebarContent
        display={{
          base: 'none',
          md: 'unset',
        }}
      />
      <Drawer isOpen={sidebar.isOpen} onClose={sidebar.onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent onClick={() => sidebar.onClose()} w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{
          base: 0,
          md: 60,
        }}
        transition=".3s ease">
        <Flex
          display={{
            base: 'set',
            md: 'none',
          }}
          as="header"
          align="center"
          justify="space-between"
          w="full"
          px="4"
          bg="brand.600"
          borderBottomWidth="1px"
          borderColor="blackAlpha.300"
          h="14">
          <SmilefixLogoSmall height="36px" />
          <IconButton
            aria-label="Menu"
            display={{
              base: 'inline-flex',
              md: 'none',
            }}
            onClick={sidebar.onOpen}
            icon={<FiMenu />}
            size="sm"
          />
        </Flex>

        <NotificationBanners/>
        <Box as="main" p="4">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
