import React, { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import { useDispatch } from 'react-redux';
import { appCreateUserWithEmailAndPassword, appSendPasswordResetEmail, LoginProps } from '../features/auth/authSlice';
import { useAppDispatch } from '../redux/store';
import { set } from 'date-fns';
import { useNavigate } from 'react-router-dom';

let forgotPasswordFormSchema = yup.object({
  email: yup.string().email().required(),
});

type ForgotPasswordFormInputs = yup.InferType<typeof forgotPasswordFormSchema>;

interface ForgotPasswordFormProps {
  register?: boolean;
  forgotPassword?: boolean;
}

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordFormInputs>({
    resolver: yupResolver(forgotPasswordFormSchema),
  });
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleForgotPassword: SubmitHandler<ForgotPasswordFormInputs> = async ({ email }) => {
    try {
      await dispatch(appSendPasswordResetEmail(email)).unwrap();
    } catch (err) {}
    setCompleted(true);
  };

  if (completed) {
    return (
      <Alert
        bgColor="white"
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px">
        <AlertIcon color="freshblue" boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Password reset sent!
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Link
            onClick={() => {
              navigate('/');
              setCompleted(false);
            }}>
            Continue to login
          </Link>
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleForgotPassword)}>
      <Stack spacing="5">
        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input placeholder="Enter your email" {...register('email', { required: true })} />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit">Reset password</Button>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};
