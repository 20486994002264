import React, { useEffect, useState } from 'react';
import { Banner } from '../components/Banner';
import unixTimestampToLocaleDatetimeString from '../lib/utils';
import { useGetUserQuery } from '../redux/apiSlice';

export default function NotificationBanners() {
  const { data: user, isLoading } = useGetUserQuery();

  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [showCardWarningBanner, setShowCardWarningBanner] = useState(false);
  const [showCancelPeriodBanner, setShowCancelPeriodBanner] = useState(false);

  useEffect(() => {
    if (user) {
      if (!user.payment_card_ok) {
        setShowCardWarningBanner(true);
      } else if (user.cancel_period_is_active) {
        setShowCancelPeriodBanner(true);
      }
    }
  }, [user]);

  //If there is no dentist data for the logged in user, display register form and ask for subscription.
  // if (user?.product) {
  //   console.log(user)
  //   if (user) {
  //     return <SelectSubscription user={user} />;
  //   }
  // }

  return (
    <>
      {showCardWarningBanner && (
        <Banner
          type="warning"
          message="Subscription inactive. Please update your payment details."
          onClick={() => setShowCardWarningBanner(false)}
        />
      )}
      {showCancelPeriodBanner && (
        <Banner
          type="info"
          message={`Subscription will be canceled in ${unixTimestampToLocaleDatetimeString(
            user?.subscription_period_end,
          )}`}
          onClick={() => setShowCancelPeriodBanner(false)}
        />
      )}
      {showSuccessBanner && (
        <Banner type="success" message="Subscription active." onClick={() => setShowSuccessBanner(false)} />
      )}
    </>
  );
}


