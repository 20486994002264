import { differenceInDays, differenceInMonths, format, isToday } from 'date-fns';

import { colors } from '../styles/colors';
import { Customer, User, Template } from '../redux/types';

/**
 * Helper function to get template by name and field from all of the templates.
 * @param templates
 * @param field
 * @param name
 */

export const getTemplateByNameAndField = (templates: Template[], field: string, name: string) => {
  const template = templates.filter((t: Template) => t.name === name && t.field === field);
  if (template && template.length) {
    return template[0];
  }
  return null;
};

export const getStatusColor = (status: 'New' | 'Urgent' | 'Seen' | 'Sent') => {
  switch (status) {
    case 'Seen':
      return colors.status.seen;
    case 'New':
      return colors.status.new;
    case 'Urgent':
      return colors.status.urgent;
    case 'Sent':
      return colors.status.sent;
  }
};

// Used in customer/proposal list view.
export const formatDate = (date: string) => {
  const dateObject = new Date(date);
  if (isToday(dateObject)) {
    return `Today ${format(dateObject, 'HH:mm')}`;
  }
  return format(dateObject, 'dd.MM.yyyy');
};

// Used in client info card.
export const getGender = (customer: Customer) => {
  switch (customer.gender) {
    case 'f':
      return 'Female';
    case 'm':
      return 'Male';
    case 'nb':
      return 'Non-binary';
    case 'x':
      return 'I prefer not to say';
    default:
      return null;
  }
};

// Used in customer card to check customer status.
export const customerStatus = (customer: Customer) =>
  customer.proposal_date_sent !== null ? 'Sent' : isUrgent(customer.date) ? 'Urgent' : customer.last_viewed !== null ? 'Seen' : 'New';

// Used to check if customer card is supposed to be shown as urgent in the list.
export const isUrgent = (date: string) => {
  const dateObject = new Date(date);
  const isUrgentAfterDays = 2;
  return differenceInDays(new Date(), dateObject) >= isUrgentAfterDays;
};

// Used to get string how long has passed since customer appeared.
export const getTimeAgo = (date: string) => {
  const dateObject = new Date(date);
  const diffInDays = differenceInDays(new Date(), dateObject);
  const diffInMonths = differenceInMonths(new Date(), dateObject);
  if (diffInMonths >= 1) {
    if (diffInMonths === 1) {
      return `${diffInMonths} month ago`;
    }
    return `${diffInMonths} months ago`;
  }
  if (diffInDays >= 1) {
    if (diffInDays === 1) {
      return `${diffInDays} day ago`;
    }
    return `${diffInDays} days ago`;
  }
  return null;
};

// JS count unix timestamps in milliseconds
const DEFAULT_CHARS_IN_UNIX_TIMESTAMP: number = 13;

export default function unixTimestampToLocaleDatetimeString(unixTimestamp: string | number | null | undefined): string {
  if (!unixTimestamp) {
    // should not land here
    return '';
  }

  const unixTimestampString = `${unixTimestamp}`;
  const unixTimestampInt = parseInt(unixTimestampString);

  let shiftedUnixTimestamp = unixTimestampInt;

  if (unixTimestampString.length < DEFAULT_CHARS_IN_UNIX_TIMESTAMP) {
    // Date(unixTimestamp) to be correct, unixTimestamp must be in milliseconds
    const diff = DEFAULT_CHARS_IN_UNIX_TIMESTAMP - unixTimestampString.length;

    shiftedUnixTimestamp = unixTimestampInt * Math.pow(10, diff);
  }

  const date = new Date(shiftedUnixTimestamp);

  const localDate = date.toLocaleDateString();
  let localTime = date.toLocaleTimeString();

  const localTimeSplitted = localTime.split(':');

  if (localTimeSplitted.length >= 2) {
    // keep just hours and minutes
    localTime = `${localTimeSplitted[0]}:${localTimeSplitted[1]}`;
  }

  return `${localDate} at ${localTime}`;
}

export const getAutomaticSignature = (user: User) => {
  return `
    <br /><br />
    <p>${user.first_name} ${user.last_name}</p>
    <p>${user.company_name}</p>
    <p>${user.city}, ${user.country}</p>
    <p>${user.phone}</p>
    <p>${user.website}</p>
    `;
};
