import { useMemo } from 'react';
import { useGetUserQuery } from '../redux/apiSlice';

export const usePermissions = () => {
  const { data: user } = useGetUserQuery();

  return useMemo(
    () => ({
      //product: user?.product.subscription_level !== undefined ? user?.product.subscription_level : null,
      subscriptionValid: !!user?.subscription_ok,
      isAdmin: !!user?.is_admin,
    }),
    [user],
  );
};
