import { Box, BoxProps, Button, Center, Divider, Flex, Heading, VStack } from '@chakra-ui/react';
import { createRef, useRef } from 'react';
import QRCode from 'react-qr-code';
import { useGetUserQuery } from '../redux/apiSlice';

interface QrCodeProps extends BoxProps {}

export default function QrCode(props: QrCodeProps) {
  const { data: user } = useGetUserQuery();
  const ref = useRef(null);

  const downloadQRCode = () => {
    const qr = ref.current
    if (!qr) {
      return;
    }
    
    const svgData = new XMLSerializer().serializeToString(qr)
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const img = new Image(500,500)
    img.onload = function () {
      canvas.width = img.width + 40
      canvas.height = img.height + 40
      if (ctx) {
      ctx.strokeRect(0, 0, canvas.width, canvas.height)
      ctx.fillStyle = "#FFFFFF"
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 20, 20)
      }
      const pngFile = canvas.toDataURL("image/png", 0)

      const downloadLink = document.createElement("a")
      downloadLink.download = "qrcode"
      downloadLink.href = `${pngFile}`
      downloadLink.target = "_blank"
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      downloadLink.remove()
    }

    

    img.src = "data:image/svg+xml;base64," + btoa(svgData)
  };

  return (
    <Box as="section" {...props}>
      <VStack spacing={4} align="left">
        <Box width='128px' height='128px'>
          <QRCode style={{
            height: '100%',
            width: '100%'
          }}
          ref={ref} size={500} value={user?.client_app_url || ''} />
        </Box>
        <Button onClick={() => downloadQRCode()} alignSelf={'flex-start'}>
          Download
        </Button>
      </VStack>
    </Box>
  );
}
