import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Container, IconButton, Stack, Text } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';

interface BannerProps {
  type: 'warning' | 'info' | 'success';
  title?: string;
  message: string;
  onClick: () => void;
}

export const Banner = (props: BannerProps) => (
  <Box as="section">
    <Box borderBottomWidth="1px" bg="bg.surface">
      <Container py={{ base: '4', md: '3.5' }}>
        <Stack
          direction="row"
          spacing={{ base: '3', md: '4' }}
          justify="space-between"
          align={{ base: 'start', md: 'center' }}>
          <Box>
            {props?.title && <Text fontWeight="medium">{props.title}</Text>}
            <Text color="fg.muted">{props.type !== 'success' && <WarningTwoIcon mr={4}/>}{props.message}</Text>
          </Box>
          <IconButton onClick={props.onClick} icon={<FiX />} variant="tertiary" aria-label="Close banner" />
        </Stack>
      </Container>
    </Box>
  </Box>
);