import { is } from 'immutable';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useGetQuestionnairesQuery } from '../redux/apiSlice';

export default function ListQuestionnairesPage() {
  const { data: questionnaires, isLoading } = useGetQuestionnairesQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (questionnaires) {
      navigate(`${questionnaires[0].id}`);
    }
  }, [questionnaires]);

  return <Loader />;
}
