import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUserId, selectUserState } from '../features/auth/authSlice'


export const useAuth = () => {
  const userId = useSelector(selectCurrentUserId)
  const state = useSelector(selectUserState);

  return useMemo(() => ({ userId, state }), [userId, state])
}
