import { Text, Box,  BoxProps, Stack } from '@chakra-ui/react';

const SMILEFIX_DENTIST_ADMIN_TERMS_OF_USE_URL = process.env.REACT_APP_SMILEFIX_DENTIST_ADMIN_TERMS_OF_USE_URL;

interface TermsOfServicePanelProps extends BoxProps {}

export const TermsOfServicePanel = (props: TermsOfServicePanelProps) => {
  return (
    <Box {...props} as="section">
      <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
        <Stack spacing="5">
          <Stack spacing="4">
            <Text textStyle="lg" fontWeight="medium">
              Privacy policy
            </Text>
            <Text textStyle="sm" color="fg.muted">
              <a href={SMILEFIX_DENTIST_ADMIN_TERMS_OF_USE_URL} target="_blank" rel="noreferrer">
                Click here to view Privacy policy.
              </a>
            </Text>
          </Stack>
          <Stack spacing="4">
            <Text textStyle="lg" fontWeight="medium">
              Support
            </Text>
            <Text textStyle="sm" color="fg.muted">
              <p>
                For support and development ideas please contact:{' '}
                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => (window.location = 'mailto:support@smilefix.net' as any)}>
                  support@smilefix.net
                </span>
              </p>
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
