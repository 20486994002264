import { QuestionIcon, SettingsIcon, LockIcon } from '@chakra-ui/icons';
import { Link, Box, Flex, Spacer, Divider } from '@chakra-ui/react';
import { FiGrid, FiPieChart, FiHelpCircle } from 'react-icons/fi';
import { SmilefixLogoSmall } from '../assets/icon/SmilefixLogoSmall';
import { NavItem } from './NavItem';
import { Link as ReachLink, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { appLogout } from '../features/auth/authSlice';
import { usePermissions } from '../hooks/usePermissions';

export const SidebarContent = ({ onClick, ...props }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdmin, subscriptionValid } = usePermissions();

  return (
    <Box
      as="nav"
      pos="fixed"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="brand.600"
      borderRightWidth="1px"
      w="60"
      px={{ base: '4', sm: '6' }}
      borderColor="black"
      {...props}>
      <Flex
        onClick={onClick}
        height="100%"
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation">
        <Box py={8}>
          <Link as={ReachLink} to="/dashboard">
            <SmilefixLogoSmall width="160px" />
          </Link>
        </Box>
        {subscriptionValid && (
          <>
            <NavItem to="/dashboard" icon={FiGrid}>
              Dashboard
            </NavItem>
            <NavItem to="statistics" icon={FiPieChart}>
              Statistics
            </NavItem>
            <NavItem to="questionnaire" icon={QuestionIcon}>
              Questionnaire
            </NavItem>
          </>
        )}
        {isAdmin  && (
          <NavItem to="questions" icon={QuestionIcon}>
            Questions (Admin)
          </NavItem>
        )}

        <Spacer />

        <NavItem to="help" icon={FiHelpCircle}>
          Help center
        </NavItem>

        <NavItem to="settings" icon={SettingsIcon}>
          Account settings
        </NavItem>
        <Divider m={1} borderColor="white" />
        <NavItem
          onClick={() => {
            dispatch(appLogout());
            navigate('/');
          }}
          icon={LockIcon}>
          Log out
        </NavItem>
      </Flex>
    </Box>
  );
};
