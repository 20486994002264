import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Flex,
  Heading,
  IconButton,
  Image,
  Spacer,
} from '@chakra-ui/react';

import { Question } from '../redux/types';

interface QuestionWithOptionProps extends CardProps {
  isSelectMode?: boolean;
  question: Question;
  onSelect?: any;
  onDelete?: any;
}

export default function QuestionWithOption({ onDelete, isSelectMode, question, onSelect, ...props }: any) {
  const { name, translations, ordering, page, resourcetype, enabled, image } = question;

  return (
    <Card maxW="sm" {...props} onClick={onSelect}>
      <CardHeader>
        <Flex>
          <Heading as="h3" size="sd">
            {translations.en.text}
          </Heading>
          <Spacer />
          {isSelectMode === true ? (
            <></>
          ) : (
            <IconButton onClick={() => onDelete(question)} icon={<DeleteIcon />} aria-label={''} />
          )}
        </Flex>
      </CardHeader>

      <CardBody paddingTop="0">
        <Image borderRadius="md" src={image} />
      </CardBody>

        <CardFooter paddingTop="0">
          <Flex justify="space-between" flexWrap="wrap" gap="4" w="full">
            <Button colorScheme="gray" flex="1">
              {translations.en.option1}
            </Button>

            <Button colorScheme="gray" flex="1">
              {translations.en.option2}
            </Button>
            <Button colorScheme="gray" flex="1">
              {translations.en.option3}
            </Button>
          </Flex>
        </CardFooter>

    </Card>
  );
}
