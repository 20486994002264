import { Text, Box, Container, Stack, HStack, Square, Icon, Button, BoxProps, Link } from '@chakra-ui/react';
import { FiFileText } from 'react-icons/fi';
import { useGetGuidesQuery } from '../redux/apiSlice';

interface HelpFileListProps extends BoxProps {}

export const HelpFileList = (props: HelpFileListProps) => {
  const { data: guides } = useGetGuidesQuery();

  return (
    <Box {...props} as="section" py={{ base: '4', md: '8' }}>
      <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
        <Stack spacing="5">
          <Stack spacing="1">
            <Text textStyle="lg" fontWeight="medium">
              Files
            </Text>
            <Text textStyle="sm" color="fg.muted">
              User manuals and marketing materials.
            </Text>
          </Stack>
          {guides &&
            guides.map((guide) => {
              return <FileItem title={guide.title} size={guide.size} url={guide.pdf} key={guide.id} />;
            })}
        </Stack>
      </Box>
    </Box>
  );
};

interface FileItemProps {
  title: string;
  size: number;
  url: string;
}

const FileItem = ({ title, size, url }: FileItemProps) => {
  const sizeInMb = size / 1024 / 1024;
  const name = new URL(url).pathname.split('/').pop();

  return (
    <Box borderWidth={{ base: '0', md: '1px' }} p={{ base: '0', md: '4' }} borderRadius="lg">
      <Stack justify="space-between" direction={{ base: 'row', md: 'row' }} spacing="5">
        <HStack spacing="3">
          <Square size="10" bg="bg.subtle" borderRadius="lg">
            <Icon as={FiFileText} boxSize="5" />
          </Square>
          <Box fontSize="sm">
            <Text color="empahsized" fontWeight="medium">
              {title}
            </Text>
            <Text color="empahsized" fontWeight="medium">
              {name}
            </Text>
            <Text color="fg.muted">{sizeInMb.toFixed(1)} MB</Text>
          </Box>
        </HStack>
        <Stack alignItems='center' spacing="3" direction={{ base: 'row' }}>
          <Button
            onClick={() => {
              window.open(url);
            }}
            variant="secondary">
            Download
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
