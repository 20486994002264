import React from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import { useDispatch } from 'react-redux';
import { appCreateUserWithEmailAndPassword, LoginProps } from '../features/auth/authSlice';
import { useAppDispatch } from '../redux/store';
import { useNavigate } from 'react-router-dom';

let registerFormSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required().min(8),
  rememberMe: yup.boolean(),
});

type RegisterFormInputs = yup.InferType<typeof registerFormSchema>;

interface LoginFormProps {
  register?: boolean;
}

export const RegisterForm = ({ register: isRegisterPage, ...props }: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RegisterFormInputs>({
    resolver: yupResolver(registerFormSchema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRegister: SubmitHandler<RegisterFormInputs> = async ({ name, password, email, rememberMe }) => {
    const login: LoginProps = { email, password };
    const userCredential = await dispatch(appCreateUserWithEmailAndPassword(login))
      .unwrap()
      .then(() => {
        console.log('ok');
      })
      .catch((err) => () => {
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <Stack spacing="5">
        <FormControl isInvalid={!!errors?.name}>
          <FormLabel>Name</FormLabel>
          <Input placeholder="Enter your email" {...register('name', { required: true })} />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input placeholder="Enter your email" {...register('email', { required: true })} />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.password}>
          <FormLabel>Password</FormLabel>
          <Input placeholder="********" type="password" {...register('password', { required: true })} />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <HStack justify="space-between">
          <Checkbox defaultChecked {...register('rememberMe')}>
            Remember me
          </Checkbox>
          <Button variant="text" size="sm" onClick={() => navigate('/forgot-password')}>
            Forgot password
          </Button>
        </HStack>
        <Button type="submit">Create account</Button>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};
