import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import { Text, Box, Heading, Link, BoxProps, Stack, Button, VStack } from '@chakra-ui/react';
import { FacebookIcon, TwitterIcon, WhatsAppIcon } from '../assets/icon/SocialIcons';

interface CustomerSupportProps extends BoxProps {}

export const CustomerSupport = (props: CustomerSupportProps) => {
  const phone = '';
  const email = 'support@smilefix.net';

  return (
    <Box {...props} as="section" py={{ base: '4', md: '8' }}>
      <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
        <Stack spacing="5">
          <Stack spacing="4">
            <Text textStyle="lg" fontWeight="medium">
              Customer support
            </Text>
            <Text textStyle="sm" color="fg.muted">
              If you require immediate help or have any questions, our support team is here to assist you. Please feel
              free to contact us at{' '}
            </Text>
          </Stack>
          <Stack spacing="3" direction={{ base: 'column', sm: 'column' }}>
            <Button
              variant="secondary"
              leftIcon={<EmailIcon />}
              iconSpacing="3"
              onClick={() => {
                window.open(`mailto:${email}`);
              }}
              >
              Email
            </Button>
            <Button
              variant="secondary"
              leftIcon={<PhoneIcon />}
              iconSpacing="3"
              onClick={() => {
                window.open(`tel:${phone}`);
              }}>
              Phone
            </Button>
            {/* <Button variant="secondary" leftIcon={<FacebookIcon />} iconSpacing="3">
              Facebook
            </Button>
            <Button variant="secondary" leftIcon={<TwitterIcon />} iconSpacing="3">
              Twitter
            </Button> */}
            <Button
              onClick={() => {
                window.open(`https://wa.me/${phone}`);
              }}
              variant="secondary"
              leftIcon={<WhatsAppIcon />}
              iconSpacing="3">
              WhatsApp
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
